<template>
  <div class="row">
    <b-col md="12" class="border-bottom-primary border-3">
      <b-row>
        <b-col md="2">
          <b-link
            class="brand-logo"
            to="/"
          >
            <vuexy-logo class="m-2 d-flex align-middle w-75"/>
          </b-link>
        </b-col>
        <b-col md="8" class="border-3 border-left-primary border-right-primary">
          <b-row class="h-100">
            <b-col md="12" class="mt-1 border-1 border-bottom-secondary height-40">
              <span class="h3 font-weight-bolder text-primary">
                {{ cName }}
              </span>
            </b-col>
            <b-col md="12" class="mt-1 height-40 align-middle justify-content-center">
              <span class="h3 font-weight-bolder text-secondary">
                {{ mName }} {{ mName != '' || lName != '' ? '>' : '' }} {{ lName }}
              </span>
            </b-col>
          </b-row>
        </b-col>
        <b-col md="2" class="m-0">
          <progress-bar
            :options="options"
            :value="Number(activityPercent.toFixed(0))"
            class="d-block w-25"
          />
        </b-col>
      </b-row>
    </b-col>
    <div :class="width >= 1000 && collapseModule ? 'col-lg-10 col-md-9 vhc-100 p-0 m-0' : 'col-12'">
      <div v-if="finalTestShow">
        <div class=" w-100 vhf-100 mb-0 p-5">
          <b-card
              border-variant="gradient-primary"
              header-bg-variant="gradient-primary"
              header-text-variant="white"
              v-if="!showFinalTestReport"
          >
            <template #header>
              <h3 class="text-white w-100 font-weight-bolder"><span class="float-left"
              >Финальный тест</span><span
                  class="float-right"
              >Вопрос {{ currentPageFinalTest }} из {{ rowsFinalTest }}</span></h3>
            </template>
            <div class="p-1">
              <b-pagination
                  v-model="currentPageFinalTest"
                  hide-goto-end-buttons
                  next-class="d-none w-0"
                  prev-class="d-none w-0"
                  :total-rows="rowsFinalTest"
                  :per-page="perPageFinalTest"
                  class="w-100"
                  align="fill"
                  size="lg"
                  limit="20"
                  :disabled="true"
              />
              <b-card-text class="mt-1 text-black"><h4 class="text-black"
                                                       v-html="questionsFinalTest[currentPageFinalTest-1] ? questionsFinalTest[currentPageFinalTest-1].text : ''"
              ></h4></b-card-text>
              <b-form-group label="Выберите вариант" v-slot="{ ariaDescribedby }"
                            v-if="questionsFinalTest[currentPageFinalTest-1] && questionsFinalTest[currentPageFinalTest-1].options.length > 0"
              >
                <b-form-radio class="mt-1 text-black" v-model="selectedFinalTest" :aria-describedby="ariaDescribedby"
                              name="some-radios" :value="value.id"
                              v-for="(value, index) in questionsFinalTest[currentPageFinalTest-1].options" :key="index"
                >
                  <span v-html="value.text"></span>
                </b-form-radio>
              </b-form-group>
              <div v-if="currentPageFinalTest < rowsFinalTest">
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="gradient-primary"
                    class="mr-1"
                    pill
                    @click="nextFinalTest"
                    v-if="selectedFinalTest > 0"
                >
                  Продолжить
                </b-button>
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="gradient-primary"
                    class="mr-1"
                    pill
                    disabled
                    v-else
                >
                  Продолжить
                </b-button>
<!--                <b-button-->
<!--                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"-->
<!--                    variant="gradient-primary"-->
<!--                    style="border-bottom-right-radius: 50px; border-top-right-radius: 50px"-->
<!--                    @click="skipFinalTest"-->
<!--                >-->
<!--                  Пропустить вопрос-->
<!--                </b-button>-->
              </div>
              <div v-else>
                <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="gradient-primary"
                    @click="successFinalTest"
                    pill
                >
                  Завершить
                </b-button>
              </div>
            </div>
          </b-card>
          <b-card title="Результат финального тестирование" v-else>
            <!-- chart -->
            <chartjs-component-doughnut-chart
                :height="275"
                :data="chartDataFinalTest.doughnutChart.data"
                :options="chartDataFinalTest.doughnutChart.options"
                class="mb-3"
            />
            <!--/ chart -->

            <!-- stocks -->
            <div
                v-for="(stock,index) in chartDataFinalTest.stockData"
                :key="stock.device"
                :class="index < chartDataFinalTest.stockData.length-1 ? 'mb-1':''"
                class="d-flex justify-content-between"
            >
              <div class="d-flex align-items-center">
                <feather-icon
                    :icon="stock.symbol"
                    size="16"
                    :class="stock.color"
                />
                <span class="font-weight-bold ml-75 mr-25">{{ stock.device }}</span>
              </div>
              <div>
                <span>{{ stock.percentage }}</span>
              </div>
            </div>
            <!--/ stocks -->
          </b-card>

        </div>
      </div>
      <div v-else>
        <iframe class="w-100 vhf-100" :src="iFrameSrc" v-if="iFrameSrc !== ''" @load="loadIFrame" ref="myFrame" id="myFrame"></iframe>
        <div class="w-100 vh-100" v-else-if="lType === 2 && lValue" v-html="lValue"></div>
        <div class=" w-100 vhf-100 mb-0 p-5" v-else-if="lType === 3">
          <b-card
              border-variant="gradient-primary"
              header-bg-variant="gradient-primary"
              header-text-variant="white"
              v-if="!showTestReport"
          >
            <template #header>
              <h3 class="text-white w-100 font-weight-bolder"><span class="float-left"
              >Вопросы > {{ mName }} {{ mName != '' || lName != '' ? '-' : '' }} {{ lName }}</span><span
                  class="float-right"
              >Вопрос {{ currentPage }} из {{ rows }}</span></h3>
            </template>
            <div class="p-1">
              <b-pagination
                  v-model="currentPage"
                  hide-goto-end-buttons
                  next-class="d-none w-0"
                  prev-class="d-none w-0"
                  :total-rows="rows"
                  :per-page="perPage"
                  class="w-100"
                  align="fill"
                  size="lg"
                  limit="20"
                  :disabled="true"
              />
              <b-card-text class="mt-1 text-black"><h4 class="text-black"
                                                       v-html="questions[currentPage-1] ? questions[currentPage-1].text : ''"
              ></h4></b-card-text>
              <b-form-group label="Выберите вариант" v-slot="{ ariaDescribedby }"
                            v-if="questions[currentPage-1] && questions[currentPage-1].options.length > 0"
              >
                <b-form-radio class="mt-1 text-black" v-model="selectedTest" :aria-describedby="ariaDescribedby"
                              name="some-radios" :value="value.id"
                              v-for="(value, index) in questions[currentPage-1].options" :key="index"
                >
                  <span v-html="value.text"></span>
                </b-form-radio>
              </b-form-group>
              <div v-if="currentPage < rows">
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="gradient-primary"
                    class="mr-1"
                    style="border-bottom-left-radius: 50px; border-top-left-radius: 50px"
                    @click="nextTest"
                    v-if="selectedTest > 0"
                >
                  Продолжить
                </b-button>
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="gradient-primary"
                    class="mr-1"
                    style="border-bottom-left-radius: 50px; border-top-left-radius: 50px"
                    disabled
                    v-else
                >
                  Продолжить
                </b-button>
                <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="gradient-primary"
                    style="border-bottom-right-radius: 50px; border-top-right-radius: 50px"
                    @click="skipTest"
                >
                  Пропустить вопрос
                </b-button>
              </div>
              <div v-else>
                <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="gradient-primary"
                    @click="successTest"
                    pill
                >
                  Завершить
                </b-button>
              </div>
            </div>
          </b-card>
          <b-card title="Результаты тестирования по модулю" v-else>
            <!-- chart -->
            <chartjs-component-doughnut-chart
                :height="275"
                :data="chartData.doughnutChart.data"
                :options="chartData.doughnutChart.options"
                class="mb-3"
            />
            <!--/ chart -->

            <!-- stocks -->
            <div
                v-for="(stock,index) in chartData.stockData"
                :key="stock.device"
                :class="index < chartData.stockData.length-1 ? 'mb-1':''"
                class="d-flex justify-content-between"
            >
              <div class="d-flex align-items-center">
                <feather-icon
                    :icon="stock.symbol"
                    size="16"
                    :class="stock.color"
                />
                <span class="font-weight-bold ml-75 mr-25">{{ stock.device }}</span>
              </div>
              <div>
                <span>{{ stock.percentage }}</span>
              </div>
            </div>
            <!--/ stocks -->
          </b-card>

        </div>
      </div>
      <div class="m-pagination d-flex justify-content-center col-lg-10 col-md-10 py-1 w-100">
        <b-button-group
            class="text-center"
        >

          <b-button
              variant="primary"
              @click="prev"
          >
            Пред.
          </b-button>
          <b-button
              variant="primary"
              @click="next"
          >
            След.
          </b-button>
        </b-button-group>
      </div>
    </div>
    <div
        :class="!collapseModule ? `m-list ${collapseToggle ? 'active' : ''}` : `col-lg-2 col-md-3 p-0 m-0 ${collapseToggle ? 'active' : ''}`"
        class=""
    >
      <div class="toggle-list-modules position-absolute bg-primary" @click="collapseToggle = !collapseToggle"
      v-if="!collapseModule"
      >
        <feather-icon icon="XCircleIcon" size="20" class="text-white "/>
      </div>
      <b-card
          class="card-transaction sidebar-right vhf-100 mb-0 mh-100 w-100"
          no-body
      >

        <b-card-body class="">
          <div
              v-for="(module, IndexModule) in modules"
              :key="IndexModule"
              class="transaction-item"
          >
            <b-media no-body>
              <b-media-body>
                <h6 class="transaction-title">
                  {{ module.name }}
                </h6>
                <b-card
                    class="card-transaction"
                    no-body
                >
                  <div class="">
                    <b-card-body class="pr-0 p-0" :set="IndexHeadNew = 0">
                      <div
                          v-for="(head, IndexHead) in module.childs"
                          :key="IndexHead"
                          class="transaction-item d-flex match-height m-0 p-1"
                          :class="activeLink > -1 && linkToLesson[activeLink][0] === IndexModule && linkToLesson[activeLink][2] === IndexHead ? 'border-1 border-info rounded-lg  animation' : ''"
                          v-if="(IndexHead > 0 ? modules[IndexModule].childs[IndexHead-1].name : '') !== head.name"
                      >
                        <b-media no-body>
                          <b-media-aside class="d-inline-block full-height">
                            <h5 class="align-middle">{{ IndexHeadNew += 1 }}.</h5>
                          </b-media-aside>
                          <b-media-body>
                            <h5 class="transaction-title p-0 m-0">
                              <b-form-group class="d-inline-block m-0 p-0">
                                <b-form-checkbox v-model="modules[IndexModule].childs[IndexHead].checked"
                                                 class="d-inline-block" @change="changeCheck(IndexModule, -1, IndexHead)"
                                />
                                <feather-icon icon="PlayIcon" @click="viewLesson(IndexModule, -1, IndexHead)" size="20"/>
                                <span class="cursor-pointer font-weight-bolder"
                                      @click="viewLesson(IndexModule, -1, IndexHead)" size="20"
                                >{{ head.name }}</span>
                              </b-form-group>
                            </h5>

                          </b-media-body>
                        </b-media>
                        <div
                            class="font-weight-bolder"
                            :class="'text-success'"
                        >
                        </div>
                      </div>
                    </b-card-body>
                  </div>
                </b-card>
              </b-media-body>
            </b-media>

          </div>
          <b-button
              v-ripple.400="'rgba(113, 102, 240, 0.15)'"
              variant="gradient-primary"
              class="mr-1 w-100"
              @click="showFinalTest"
              v-if="questionsFinalTest.length > 0"
              rounded
          >
            Сдать экзамен
          </b-button>
        </b-card-body>
      </b-card>
    </div>
    <b-modal
        id="modal-lg"
        dialog-class="w-100 h-100"
        content-class="w-100 h-100"
        size="xl"
        centered
        ok-only
        @click="modalShow = !modalShow"
        ok-title="Закрыть"
        v-model="modalShow"
    >
      <iframe :src="showPath" frameborder="0" class="w-100 h-100"></iframe>
    </b-modal>
  </div>
</template>

<script>
import {
  BButtonGroup,
  BRow,
  BCol,
  BAvatar,
  BBadge,
  BFormRating,
  BTab,
  BTabs,
  BCardText,
  BPagination,
  BCard,
  BCardHeader,
  BCardTitle,
  BCardBody,
  BMediaBody,
  BMedia,
  BMediaAside,
  BFormCheckbox,
  BFormGroup,
  BLink,
  BButton,
  BFormRadio,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import router from '@/router'
import ToastificationContent from '@core/components/toastification/ToastificationContent'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import ProgressBar from 'vuejs-progress-bar'
import ChartjsComponentDoughnutChart from './components/ChartjsComponentDoughnutChart.vue'
import { $themeColors } from '@themeConfig'

export default {
  components: {
    BButtonGroup,
    ProgressBar,
    VuexyLogo,
    BRow,
    BCol,
    BAvatar,
    BBadge,
    BFormRating,
    BTab,
    BTabs,
    BCardText,
    BPagination,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BMediaBody,
    BMedia,
    BMediaAside,
    BFormCheckbox,
    BFormGroup,
    BLink,
    BButton,
    BFormRadio,
    ChartjsComponentDoughnutChart,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      modalShow: false,
      showPath: '',
      chartData: {
        chartColors: {
          primaryColorShade: '#836AF9',
          yellowColor: '#ffe800',
          successColorShade: '#28dac6',
          warningColorShade: '#ffe802',
          warningLightColor: '#FDAC34',
          infoColorShade: '#299AFF',
          greyColor: '#4F5D70',
          blueColor: '#2c9aff',
          blueLightColor: '#84D0FF',
          greyLightColor: '#EDF1F4',
          tooltipShadow: 'rgba(0, 0, 0, 0.25)',
          lineChartPrimary: '#666ee8',
          lineChartDanger: '#ff4961',
          labelColor: '#6e6b7b',
          grid_line_color: 'rgba(200, 200, 200, 0.2)',
        },
        doughnutChart: {
          options: {
            responsive: true,
            maintainAspectRatio: false,
            responsiveAnimationDuration: 500,
            cutoutPercentage: 60,
            legend: { display: false },
            tooltips: {
              callbacks: {
                label(tooltipItem, data) {
                  const label = data.datasets[0].labels[tooltipItem.index] || ''
                  const value = data.datasets[0].data[tooltipItem.index]
                  const output = ` ${label} : ${value}`
                  return output
                },
              },
              // Updated default tooltip UI
              shadowOffsetX: 1,
              shadowOffsetY: 1,
              shadowBlur: 8,
              shadowColor: 'rgba(0, 0, 0, 0.25)',
              backgroundColor: $themeColors.light,
              titleFontColor: $themeColors.dark,
              bodyFontColor: $themeColors.dark,
            },
          },
          data: {
            datasets: [
              {
                labels: ['Правильный ответ', 'Неправильный ответ'],
                data: [0, 0],
                backgroundColor: ['#28c76f', '#ea5455'],
                borderWidth: 0,
                pointStyle: 'rectRounded',
              },
            ],
          },
        },
        stockData: [
          {
            device: 'Правильный ответ',
            symbol: 'CheckIcon',
            color: 'text-success',
            percentage: 0,
          },
          {
            device: 'Неправильный ответ',
            symbol: 'XIcon',
            color: 'text-danger',
            percentage: 0,
          },
        ],
      },
      chartDataFinalTest: {
        chartColors: {
          primaryColorShade: '#836AF9',
          yellowColor: '#ffe800',
          successColorShade: '#28dac6',
          warningColorShade: '#ffe802',
          warningLightColor: '#FDAC34',
          infoColorShade: '#299AFF',
          greyColor: '#4F5D70',
          blueColor: '#2c9aff',
          blueLightColor: '#84D0FF',
          greyLightColor: '#EDF1F4',
          tooltipShadow: 'rgba(0, 0, 0, 0.25)',
          lineChartPrimary: '#666ee8',
          lineChartDanger: '#ff4961',
          labelColor: '#6e6b7b',
          grid_line_color: 'rgba(200, 200, 200, 0.2)',
        },
        doughnutChart: {
          options: {
            responsive: true,
            maintainAspectRatio: false,
            responsiveAnimationDuration: 500,
            cutoutPercentage: 60,
            legend: { display: false },
            tooltips: {
              callbacks: {
                label(tooltipItem, data) {
                  const label = data.datasets[0].labels[tooltipItem.index] || ''
                  const value = data.datasets[0].data[tooltipItem.index]
                  const output = ` ${label} : ${value}`
                  return output
                },
              },
              // Updated default tooltip UI
              shadowOffsetX: 1,
              shadowOffsetY: 1,
              shadowBlur: 8,
              shadowColor: 'rgba(0, 0, 0, 0.25)',
              backgroundColor: $themeColors.light,
              titleFontColor: $themeColors.dark,
              bodyFontColor: $themeColors.dark,
            },
          },
          data: {
            datasets: [
              {
                labels: ['Правильный ответ', 'Неправильный ответ'],
                data: [0, 0],
                backgroundColor: ['#28c76f', '#ea5455'],
                borderWidth: 0,
                pointStyle: 'rectRounded',
              },
            ],
          },
        },
        stockData: [
          {
            device: 'Правильный ответ',
            symbol: 'CheckIcon',
            color: 'text-success',
            percentage: 0,
          },
          {
            device: 'Неправильный ответ',
            symbol: 'XIcon',
            color: 'text-danger',
            percentage: 0,
          },
        ],
      },
      classAnswer: '',
      finalTestShow: false,
      selectedTest: 0,
      selectedFinalTest: 0,
      currentPage: 1,
      currentPageFinalTest: 1,
      perPage: 1,
      perPageFinalTest: 1,
      rows: 12,
      rowsFinalTest: 12,
      questions: [],
      questionsFinalTest: [],
      answeredQuestion: {},
      answeredQuestionFinalTest: {},
      correctAnswer: 0,
      wrongAnswer: 0,
      showTestReport: false,
      showFinalTestReport: false,
      finalTestReport: {},
      courseDetails: {},
      collapseModule: true,
      collapseToggle: false,
      modules: [],
      iFrameSrc: '',
      cName: '',
      mName: '',
      lName: '',
      lType: -1,
      lValue: '',
      countLesson: 0,
      countLessonChecked: 0,
      activityPercent: 0,
      linkToLesson: [],
      activeLink: -1,
      options: {
        text: {
          color: '#0B63AC',
          fontSize: 14,
          fontFamily: 'Montserrat, Helvetica, Arial, serif',
          shadowEnable: false,
          shadowColor: '#0B63AC',
          dynamicPosition: true,
          hideText: false,
        },
        progress: {
          color: '#0B63AC',
          backgroundColor: '#FFFFFF',
          inverted: false,
        },
        layout: {
          height: 60,
          width: 60,
          verticalTextAlign: 41,
          horizontalTextAlign: 15,
          zeroOffset: 0,
          strokeWidth: 10,
          progressPadding: 30,
          type: 'circle',
        }
      },
      width: document.documentElement.clientWidth,
      height: document.documentElement.clientHeight,
    }
  },
  setup() {
    const { id } = router.currentRoute.params
    const m = parseInt(router.currentRoute.params.m ? router.currentRoute.params.m : -1)
    const h = parseInt(router.currentRoute.params.h ? router.currentRoute.params.h : -1)
    const l = parseInt(router.currentRoute.params.l ? router.currentRoute.params.l : -1)
    return {
      id,
      m,
      h,
      l,
    }
  },
  mounted() {
    window.addEventListener('resize', this.getDimensions)
  },
  unmounted() {
    window.removeEventListener('resize', this.getDimensions)
  },
  created() {
    this.view()
  },
  methods: {
    getParentAnchor(element) {
      while (element !== null) {
        if (element.tagName && element.tagName.toUpperCase() === 'A') {
          return element
        }
        element = element.parentNode
      }
      return null
    },
    Handler() {
      alert('works')
    },
    loadIFrame() {
      // let x = this.$refs.myFrame.iframeEl
      // console.log(x)
      // let y = (x.contentWindow || x.contentDocument)
      // if (y.document) {
      //   y = y.document
      // }
      // console.log(y.body)
      // y.body.style.backgroundColor = 'red'
      let iframe = document.getElementById('myFrame')
      // console.log(iframe.contentWindow.document.body.getElementsByTagName('a'))
      let that = this
      iframe.contentWindow.document.body.addEventListener('click', e => {
        var anchor = that.getParentAnchor(e.target)
        if(anchor !== null && anchor.attributes['data-path'] && anchor.attributes['data-path'] !== null && anchor.attributes['data-path'].value !== null) {
          that.modalShow = true
          that.showPath = anchor.attributes['data-path'].value
        }
      }, false)

    },
    changeCheck(m, h, l) {
      if (m > -1) {
        this.mName = this.modules[m].name
        if (h > -1) {
          if (l > -1) {
            this.$http.post(`https://edu.startupchoikhona.tj/backend/api/student/course/${this.id}/read/${this.modules[m].childs[h].childs[l].id}`, {
              status: this.modules[m].childs[h].childs[l].checked,
            })
              .then(response => {
                console.log(response)
              })
              .catch(error => {
                console.log(error)
              })
          }
        } else if (l > -1) {
          this.$http.post(`https://edu.startupchoikhona.tj/backend/api/student/course/${this.id}/read/${this.modules[m].childs[l].id}`, {
            status: this.modules[m].childs[l].checked,
          })
            .then(response => {
              console.log(response)
            })
            .catch(error => {
              console.log(error)
            })
        }
      }
      this.calcPercentActivity()
    },
    nextTest() {
      if (this.selectedTest > 0) {
        this.answeredQuestion[this.questions[this.currentPage - 1].id] = {
          status: false,
          id: this.selectedTest,
        }
        let that = this
        Array.from(this.questions[this.currentPage - 1].options, value => {
          if (value.is_correct === true && value.id === that.selectedTest) {
            that.answeredQuestion[that.questions[that.currentPage - 1].id] = {
              status: true,
              id: that.selectedTest,
            }
            return
          }
        })
        this.selectedTest = 0
        this.currentPage += 1
      }
    },
    nextFinalTest() {
      if (this.selectedFinalTest > 0) {
        this.answeredQuestionFinalTest[this.questionsFinalTest[this.currentPageFinalTest - 1].id] = {
          status: false,
          id: this.selectedFinalTest,
        }
        this.selectedFinalTest = 0
        this.currentPageFinalTest += 1
      }
    },
    skipTest() {
      this.selectedTest = 0
      this.answeredQuestion[this.questions[this.currentPage - 1].id] = {
        status: false,
        id: this.selectedTest,
      }
      this.currentPage += 1
    },
    skipFinalTest() {
      this.selectedFinalTest = 0
      this.answeredQuestionFinalTest[this.questionsFinalTest[this.currentPageFinalTest - 1].id] = {
        status: false,
        id: this.selectedFinalTest,
      }
      this.currentPageFinalTest += 1
    },
    successTest() {
      this.showTestReport = true
      this.answeredQuestion[this.questions[this.currentPage - 1].id] = {
        status: false,
        id: this.selectedTest,
      }
      this.answeredQuestion[this.questions[this.currentPage - 1].id] = {
        status: false,
        id: this.selectedTest,
      }
      let that = this
      Array.from(this.questions[this.currentPage - 1].options, value => {
        if (value.is_correct === true && value.id === that.selectedTest) {
          that.answeredQuestion[that.questions[that.currentPage - 1].id] = {
            status: true,
            id: that.selectedTest,
          }
          return
        }
      })
      Array.from(Object.keys(this.answeredQuestion), value => {
        if(that.answeredQuestion[value].status) {
          that.correctAnswer += 1
        } else {
          that.wrongAnswer += 1
        }
      })
      this.chartData.doughnutChart.data.datasets[0].data = [
        this.correctAnswer,
        this.wrongAnswer,
      ]
      this.chartData.stockData[0].percentage = this.correctAnswer
      this.chartData.stockData[1].percentage = this.wrongAnswer
    },
    successFinalTest() {
      this.showFinalTestReport = true
      this.answeredQuestionFinalTest[this.questionsFinalTest[this.currentPageFinalTest - 1].id] = {
        status: false,
        id: this.selectedFinalTest,
      }
      this.$http.post(`https://edu.startupchoikhona.tj/backend/api/website/courses/${this.id}/final-test/save`, {
        data: this.answeredQuestionFinalTest,
        questions: this.questionsFinalTest,
      })
        .then(response => {
          this.finalTestReport = response.data.data
          this.chartDataFinalTest.doughnutChart.data.datasets[0].data = [
            response.data.data.correct,
            response.data.data.wrong,
          ]
          this.chartDataFinalTest.stockData[0].percentage = response.data.data.correct
          this.chartDataFinalTest.stockData[1].percentage = response.data.data.wrong
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ошибка',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.response.data.message,
            },
          })
        })
    },
    scrollToLesson() {
      document.querySelector('div.transaction-item.d-flex.match-height.m-0.p-1.border-1.border-info.rounded-lg.animation').scrollIntoView({ block: 'center', behavior: 'smooth' })
    },
    prev() {
      if (this.activeLink > 0) {
        this.activeLink -= 1
        this.viewLesson(this.linkToLesson[this.activeLink][0], this.linkToLesson[this.activeLink][1], this.linkToLesson[this.activeLink][2])
      }
      this.scrollToLesson()
    },
    next() {
      if (this.activeLink >= -1 && this.activeLink < this.linkToLesson.length - 1) {
        this.activeLink += 1
        this.viewLesson(this.linkToLesson[this.activeLink][0], this.linkToLesson[this.activeLink][1], this.linkToLesson[this.activeLink][2])
      }
      this.scrollToLesson()
    },
    createLinks() {
      Array.from(this.modules, (value, index) => {
        Array.from(value.childs, (mChild, indexMChild) => {
          if (mChild.type > 1) {
            this.linkToLesson.push([
              index,
              -1,
              indexMChild,
            ])
            if(this.m === index && this.l === indexMChild && this.h === -1) {
              this.activeLink = this.linkToLesson.length - 1
            } else if (this.activeLink === -1 && !mChild.checked) {
              this.activeLink = this.linkToLesson.length - 1
            }
          } else if (mChild.childs) {
            Array.from(mChild.childs, (hChild, indexHChild) => {
              if (hChild.type > 1) {
                this.linkToLesson.push([
                  index,
                  indexMChild,
                  indexHChild,
                ])
                if(this.m === index && this.l === indexHChild && this.h === indexMChild) {
                  this.activeLink = this.linkToLesson.length - 1
                } else if (this.activeLink === -1 && !hChild.checked) {
                  this.activeLink = this.linkToLesson.length - 1
                }
              }
            })
          }
        })
      })
      if (this.linkToLesson.length > 0 && this.activeLink === -1) {
        this.next()
      } else if (this.activeLink !== -1) {
        this.viewLesson(this.linkToLesson[this.activeLink][0], this.linkToLesson[this.activeLink][1], this.linkToLesson[this.activeLink][2])
      }
    },
    calcPercentActivity() {
      this.countLesson = 0
      this.countLessonChecked = 0
      // eslint-disable-next-line array-callback-return
      Array.from(this.modules, value => {
        // eslint-disable-next-line array-callback-return
        Array.from(value.childs, mChild => {
          if (mChild.type > 1) {
            this.countLesson += 1
            if (mChild.checked && mChild.checked === true) {
              this.countLessonChecked += 1
            }
          } else if (mChild.childs) {
            Array.from(mChild.childs, hChild => {
              if (hChild.type > 1) {
                this.countLesson += 1
              }
              if (hChild.checked && hChild.checked === true) {
                this.countLessonChecked += 1
              }
            })
          }
        })
      })
      this.activityPercent = this.countLesson > 0 && this.countLessonChecked > 0 ? (this.countLessonChecked * 100) / this.countLesson : 0
    },
    viewLesson(m, h, l) {
      this.finalTestShow = false
      if (m > -1) {
        this.mName = this.modules[m].name
        if (h > -1) {
          if (l > -1) {
            this.lName = this.modules[m].childs[h].childs[l].name
            this.lType = this.modules[m].childs[h].childs[l].type
            if (this.lType === 2 && this.modules[m].childs[h].childs[l].path) {
              this.iFrameSrc = `https://edu.startupchoikhona.tj/backend${this.modules[m].childs[h].childs[l].path}`
            } else if (this.lType === 2 && this.modules[m].childs[h].childs[l].value) {
              this.iFrameSrc = ''
              this.lValue = this.modules[m].childs[h].childs[l].value
            } else {
              this.iFrameSrc = ''
              this.lValue = ''
              if (this.lType === 3) {
                this.rows = this.modules[m].childs[h].childs[l].questions.length
                this.questions = this.modules[m].childs[h].childs[l].questions
              }
            }
            this.modules[m].childs[h].childs[l].checked = true
            this.$http.post(`https://edu.startupchoikhona.tj/backend/api/student/course/${this.id}/read/${this.modules[m].childs[h].childs[l].id}`, {
              status: true,
            })
                .then(response => {
                  console.log(response)
                })
                .catch(error => {
                  console.log(error)
                })
            Array.from(this.linkToLesson, (value, index) => {
              if (value[0] === m && value[1] === h && value[2] === l) {
                this.activeLink = index
                return
              }
            })
          }
        } else if (l > -1) {
          this.lName = this.modules[m].childs[l].name
          this.lType = this.modules[m].childs[l].type
          if (this.lType === 2 && this.modules[m].childs[l].path) {
            this.iFrameSrc = `https://edu.startupchoikhona.tj/backend${this.modules[m].childs[l].path}`
          } else if (this.lType === 2 && this.modules[m].childs[l].value) {
            this.lValue = this.modules[m].childs[l].value
            this.iFrameSrc = ''
          } else {
            this.iFrameSrc = ''
            this.lValue = ''
            if (this.lType === 3) {
              this.rows = this.modules[m].childs[l].questions.length
              this.questions = this.modules[m].childs[l].questions
              console.log(this.questions)
            }
          }
          this.modules[m].childs[l].checked = true
          this.$http.post(`https://edu.startupchoikhona.tj/backend/api/student/course/${this.id}/read/${this.modules[m].childs[l].id}`, {
            status: true,
          })
            .then(response => {
              console.log(response)
            })
            .catch(error => {
              console.log(error)
            })
          Array.from(this.linkToLesson, (value, index) => {
            if (value[0] === m && value[1] === -1 && value[2] === l) {
              this.activeLink = index
              return
            }
          })
        }
      }
      this.calcPercentActivity()
    },
    getDimensions() {
      this.width = document.documentElement.clientWidth
      this.height = document.documentElement.clientHeight
      if (this.width < 1000) {
        this.collapseModule = false
      } else {
        this.collapseModule = true
      }
    },
    view() {
      this.$http.post(`https://edu.startupchoikhona.tj/backend/api/website/courses/${this.id}/view`, {})
        .then(response => {
          const { data } = response.data
          this.courseDetails = data
          this.cName = this.courseDetails.title
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ошибка',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.response.data.message,
            },
          })
        })
      this.$http.post(`https://edu.startupchoikhona.tj/backend/api/website/courses/${this.id}/modules`, {})
        .then(response => {
          this.modules = response.data
          this.calcPercentActivity()
          this.createLinks()
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ошибка',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.response.data.message,
            },
          })
        })
      this.$http.post(`https://edu.startupchoikhona.tj/backend/api/website/courses/${this.id}/final-test`, {})
        .then(response => {
          if (response.data.length > 0) {
            this.questionsFinalTest = response.data[0].questions
            this.rowsFinalTest = response.data[0].questions.length
          }
        })
        .catch(error => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Ошибка',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
              text: error.response.data.message,
            },
          })
        })
    },
    getFinalTests() {

    },
    showFinalTest() {
      this.finalTestShow = true
    },
  },
}
</script>

<style scoped>
.toggle-list-modules {
  top: 0;
  left: 0;
  z-index: 999999;
  transform: translate(-50px, 70px);
  padding: 8px 15px;
  border-top-left-radius: 22px;
  border-bottom-left-radius: 22px;
  cursor: pointer;
}

.vhc-100{
  height: calc(100vh - 125px);
}

.vhf-100{
  height: calc(100vh - 110px);
}

.sidebar-right{
  overflow: hidden;
  overflow-y: auto;
}

.m-list {
  width: 300px;
  position: fixed;
  right: -300px;
  height: 100vh;
  transition: all .4s;
}

.m-list .card {
  height: 100%;
}

.active {
  right: 0;
}

.m-pagination {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: auto;
  background-color: rgba(255, 255, 255, .8);
}

.animation {
  display: block;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 0 0 rgba(0, 207, 232, 0.4);
  animation: pulse 2s infinite;
}

@-webkit-keyframes pulse {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 169, 44, 0.4);
  }
  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(0, 207, 232, 0);
  }
  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(0, 207, 232, 0);
  }
}

@keyframes pulse {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(0, 207, 232, 0.4);
    box-shadow: 0 0 0 0 rgba(0, 207, 232, 0.4);
  }
  70% {
    -moz-box-shadow: 0 0 0 10px rgba(0, 207, 232, 0);
    box-shadow: 0 0 0 10px rgba(0, 207, 232, 0);
  }
  100% {
    -moz-box-shadow: 0 0 0 0 rgba(0, 207, 232, 0);
    box-shadow: 0 0 0 0 rgba(0, 207, 232, 0);
  }
}
</style>
